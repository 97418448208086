<script>
import ModalVideo from '@/components/ModalVideo'
import LayoutMixin from '@/components/LayoutMixin'
import PermissionMixin from '@/components/Permission/PermissionMixin'
import { mapGetters } from 'vuex'
import '@/components/Tooltip/NoPermissionTooltip.js'

export default {
  name: 'FarmHeader',

  mixins: [LayoutMixin, PermissionMixin],

  components: {
    ModalVideo,
  },

  data() {
    return {
      urlAjuda: 'https://www.youtube.com/embed/_bACnFx7JmQ',
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarm', 'enabledFarm']),
    ...mapGetters('user', ['isUSA']),
    mobile() {
      const breakpoint = this.$vuetify.breakpoint.name
      return ['sm', 'xs'].includes(breakpoint)
    },
    shouldDisableFarmCreation() {
      if (this.currentFarm) {
        return !this.hasPermission(this.$p.farm.create) || !this.enabledFarm
      } else {
        return !this.hasPermission(this.$p.farm.create)
      }
    },
    tutorialToggle() {
      return process.env.VUE_APP_TUTORIAL_ENABLED === 'true' && !this.isUSA
    },
  },

  methods: {
    createFarm() {
      this.$emit('clear-current-farm')
      this.$emit('open-modal-farms')
    },
  },
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-row v-if="mobile" class="flex-container ml-0">
        <v-col cols="6" xs="3" sm="3" style="font-size: 22px">
          {{ $t('fazenda.fazendas') }}
        </v-col>

        <v-col
          v-if="tutorialToggle"
          xs="6"
          sm="3"
          style="font-size: 12px"
          class="d-flex justify-end align-center"
        >
          <modal-video :url-video="urlAjuda" class="mr-5 d-inline" />
        </v-col>

        <v-col xs="6" sm="3" style="font-size: 12px">
          <a style="color: #8bc34a" href="/api-fazendas/importar/">
            <b>
              {{ $t('safra.register_previous_season') }}
            </b>
          </a>
        </v-col>

        <v-col xs="4" sm="3" class="d-flex justify-end">
          <no-permission-tooltip :hide="!shouldDisableFarmCreation">
            <v-btn
              dark
              color="#8bc34a"
              height="30px"
              width="130px"
              style="font-size: 10px"
              class="rounded-lg mr-5"
              :disabled="shouldDisableFarmCreation"
              @click="createFarm"
            >
              {{ $t('fazenda.create_farm') }}
            </v-btn>
          </no-permission-tooltip>
        </v-col>
      </v-row>

      <v-row v-else class="d-inline-flex mt-4" :class="'ml-3'">
        <div class="display-1">{{ $t('fazenda.fazendas') }}</div>
        <div v-if="tutorialToggle" class="ml-5">
          <modal-video
            event="tutorial_programa"
            name="header_fazenda"
            :url-video="urlAjuda"
          />
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}
.left-position {
  margin-left: auto;
}
</style>
