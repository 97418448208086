<script>
import BagPrice from '@/components/Inputs/BagPrice/BagPrice.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import '@/components/Tooltip/NoPermissionTooltip.js'

export default {
  name: 'ModalFazenda',

  mixins: [PermissionMixin, validationMixin],

  components: { BagPrice },

  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    farmId: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      estados: [],
      municipios: [],
      fazenda: {},
      countries: [],
      loading: false,
      loadingEstados: false,
      loadingMunicipios: false,
      saving: false,
    }
  },

  validations: {
    fazenda: {
      nome: { required },
      estado: {
        id: {
          required,
        },
      },

      municipio: {
        id: {
          required,
        },
      },
      bag_price: {
        crop_id: { required },
        currency_id: { required },
        price: { required },
      },
    },
  },

  watch: {
    dialog: {
      async handler(isOpened) {
        if (isOpened) {
          this.countries = this.mountCountries()
          if (this.farmId) {
            await this.getFarm(this.farmId)
          } else {
            this.fazenda = this.mountFarm()
            await this.listStates()
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('user', ['isUSA', 'getCountry']),
    municipioLabel() {
      return this.$t('municipios.municipios')
    },
    estadoLabel() {
      return this.$t('estados.estados')
    },
    bagPrices() {
      return this.fazenda.bag_price
    },
    farmHasPlots() {
      return this.fazenda.total_talhoes > 0
    },
    farmCountryId() {
      return this.fazenda?.pais?.id
    },
    defaultBagPrices() {
      const crops = {
        1: [1, 2],
        2: [1, 2],
        3: [1],
      }

      return crops[this.getCountry].map((crop_id) => {
        return {
          crop_id,
          price: 0,
        }
      })
    },
    shouldDisableFarmEdit() {
      return !this.hasPermission(this.$p.farm.edit)
    },
  },

  methods: {
    mountFarm() {
      return {
        id: null,
        estado: { id: null },
        municipio: { id: null },
        pais: this.countries.find((item) => !item.disabled),
        nome: '',
        /** FIXME: se monta o bag_price com os valores default o edit para de funcionar,
         * se não montar o bag_price com os valores default, o create para de funcionar
         *
         */
        bag_price: this.defaultBagPrices,
      }
    },

    mountCountries() {
      return [
        { id: 1, nome: 'Brasil', disabled: this.isUSA },
        { id: 2, nome: 'Paraguay', disabled: this.isUSA },
        { id: 3, nome: 'United States of America' },
      ]
    },

    async getFarm(farmId) {
      try {
        this.loading = true
        let response = await this.$api.fazendas.get(farmId)
        this.fazenda = await this.parseFazenda(response.data)

        this.listStates()
        this.listCities()

        this.loading = false
      } catch (e) {
        this.loading = false
        this.$root.$emit('notify', 'error', this.$t('fazenda.carregar.erro'))
      }
    },

    parseFazenda(fazenda) {
      const farm = {
        ...fazenda,
      }

      farm.municipio = { nome: fazenda.municipio, id: fazenda.municipio_id }
      farm.estado = { id: fazenda.estado_id }
      farm.pais = { id: fazenda.pais_id }

      if (!fazenda.bag_price?.length) {
        farm.bag_price = this.defaultBagPrices
      }

      return farm
    },

    changePais() {
      this.fazenda.municipio = { id: null }
      this.fazenda.estado = { id: null }
      this.municipios = []
      this.estados = []
      this.listStates()
    },

    async listStates() {
      if (!this.farmCountryId) {
        return
      }

      this.loadingEstados = true

      try {
        let response = await this.$api.estados.get(this.farmCountryId)

        this.estados = this.parseEstados(response.data)

        this.loadingEstados = false
      } catch (err) {
        this.loadingEstados = false
        this.$root.$emit('notify', 'error', this.$t('estados.carregar.erro'))
      }
    },

    parseEstados(estados) {
      return estados.map((e) => ({ id: e.id, nome: e.nome }))
    },

    async listCities(clear = null) {
      if (!this.fazenda?.estado?.id) return

      this.loadingMunicipios = true

      try {
        let response = await this.$api.municipios.get(this.fazenda.estado.id)

        this.municipios = this.parseMunicipios(response.data)

        this.loadingMunicipios = false

        if (clear) this.fazenda.municipio = null
      } catch (err) {
        this.loadingMunicipios = false
        this.$root.$emit('notify', 'error', this.$t('municipios.carregar.erro'))
      }
    },

    parseMunicipios(municipios) {
      return municipios.map((m) => ({ nome: m.nome, id: m.id }))
    },

    notValidPrice() {
      const validate = this.bagPrices.map((item) => {
        return item.price <= 0
      })

      validate.forEach((item) => {
        if (item) {
          this.$root.$emit('notify', 'error', this.$t('required_price'))
        }
      })

      return validate.includes(true)
    },

    async submit() {
      this.$v.$touch()

      if (this.notValidPrice()) {
        return
      }

      try {
        this.saving = true

        const isEditing = this.fazenda.id ? true : false

        const action = isEditing
          ? this.$api.fazendas.update
          : this.$api.fazendas.create
        const response = await action(this.fazenda)

        const farm = response.data

        this.$emit('set-current-farm', {
          id: farm.id,
          name: farm.nome,
          location: {
            city: { id: farm.mucicipio_id, name: farm.municipio },
            state: { id: farm.estado_id, name: farm.estado, acronym: farm.uf },
            country: { id: farm.pais_id, name: farm.pais },
            latitude: farm.latitude,
            longitude: farm.longitude,
          },
        })
        this.saving = false
        this.close()

        if (!isEditing) this.$emit('open-confirmation-dialog')
      } catch (err) {
        this.saving = false
        this.$root.$emit('notify', 'error', this.$t('fazenda.salvar.erro'))
        throw err
      }
    },

    clear() {
      this.$v.$reset()
      this.fazenda = this.mountFarm()
    },

    close() {
      this.clear()
      this.$emit('close')
    },

    requiredErrorMessage(hasError) {
      if (hasError) {
        return this.$t('campo_obrigatorio')
      }
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      :value="dialog"
      persistent
      @keydown.esc="close"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-if="!loading"
    >
      <template #activator="{ on }">
        <div v-on="on" style="height: 100% !important; width: 100% !important">
          <slot></slot>
        </div>
      </template>
      <v-card>
        <!-- header toolbar -->
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('fazenda.cadatro_fazenda') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- content -->
        <div style="margin: 30px">
          <form @keydown.enter="submit">
            <v-row>
              <!-- Nome -->
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="fazenda.nome"
                  :label="$t('fazenda.nome_da_fazenda')"
                  required
                  type="text"
                  :disabled="saving"
                  :error-messages="requiredErrorMessage($v.fazenda.nome.$error)"
                ></v-text-field>
              </v-col>

              <!-- País -->
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-select
                  v-model="fazenda.pais"
                  :items="countries"
                  item-value="id"
                  item-text="nome"
                  single-line
                  hide-details
                  return-object
                  :disabled="saving || farmHasPlots"
                  @change="changePais"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <!-- Estados -->
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-select
                  v-model="fazenda.estado"
                  :items="estados"
                  item-value="id"
                  item-text="nome"
                  single-line
                  :label="estadoLabel"
                  hide-details
                  return-object
                  :disabled="!farmCountryId || saving || farmHasPlots"
                  :loading="loadingEstados"
                  @change="listCities(true)"
                  :error-messages="
                    requiredErrorMessage($v.fazenda.estado.$error)
                  "
                >
                </v-select>
              </v-col>

              <!-- Municipio -->
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-select
                  v-model="fazenda.municipio"
                  :items="municipios"
                  item-value="id"
                  item-text="nome"
                  single-line
                  :label="municipioLabel"
                  hide-details
                  return-object
                  :disabled="farmHasPlots"
                  :error-messages="
                    requiredErrorMessage($v.fazenda.municipio.$error)
                  "
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row class="pt-5">
              <v-col
                v-for="bag_price in fazenda.bag_price"
                :key="bag_price.crop_id"
                cols="12"
                sm="12"
                md="6"
                lg="6"
              >
                <bag-price
                  v-model="bag_price.price"
                  :crop-id="bag_price.crop_id"
                />
              </v-col>
            </v-row>
          </form>
        </div>
        <div>
          <v-row style="display: flex; justify-content: center">
            <no-permission-tooltip :hide="!shouldDisableFarmEdit">
              <v-btn
                color="primary"
                dark
                :disabled="shouldDisableFarmEdit"
                :loading="saving"
                @click="submit"
              >
                {{ $t('salvar') }}
              </v-btn>
            </no-permission-tooltip>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
