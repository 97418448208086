<template>
  <div>
    <permission-failed-message v-if="!hasPermission(this.$p.farm.list)" />
    <div v-else>
      <fazendas-header @open-modal-farms="openModalFarms" />
      <fazendas-list
        @open-modal-farms="openModalFarms"
        @open-modal-plot="openModalPlot"
      />
    </div>
    <modal-fazenda
      v-if="dialogModalFarm"
      :farm-id="farmId"
      :dialog="dialogModalFarm"
      @set-current-farm="setFarm"
      @open-confirmation-dialog="openConfirmationDialog"
      @close="reloadFarmsList"
    />
    <dialog-confirmation
      v-if="showConfirmationDialog"
      ref="dialogConfirmation"
      :dialog="showConfirmationDialog"
      :title="$t('talhoes.criar_pergunta')"
      :text="$t('talhoes.text_criar')"
      @agree="openModalPlot(farm)"
      @disagree="closeConfirmationDialog"
    />
    <modal-talhoes
      v-if="dialogPlotModal"
      :farm="selectedFarm"
      @close-modal="closeModalPlot"
      @reload-farms-list="reloadFarmsList"
    />
  </div>
</template>

<script>
import FazendasHeader from '@/pages/fazendas/Header.vue'
import FazendasList from '@/pages/fazendas/List.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import ModalFazenda from '@/pages/fazendas/ModalFazenda.vue'
import DialogConfirmation from '@/pages/fazendas/DialogConfirmation.vue'
import ModalTalhoes from '@/pages/fazendas/ModalTalhoes.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FazendasHome',

  mixins: [PermissionMixin],

  components: {
    FazendasHeader,
    FazendasList,
    ModalFazenda,
    DialogConfirmation,
    ModalTalhoes,
  },

  data() {
    return {
      farm: {
        id: null,
      },
      dialogModalFarm: false,
      dialogPlotModal: false,
      selectedFarm: null,
      showConfirmationDialog: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['farms']),
    farmId() {
      return this.farm?.id
    },
  },

  methods: {
    ...mapActions('farms', ['fetchFarms']),
    openConfirmationDialog() {
      setTimeout(() => {
        this.showConfirmationDialog = true
      }, 1200)
    },
    closeConfirmationDialog() {
      this.showConfirmationDialog = false
    },
    openModalFarms(farm) {
      this.dialogModalFarm = true
      this.setFarm(farm)
    },
    openModalPlot(farm) {
      this.selectedFarm = farm
      this.showConfirmationDialog = false
      this.dialogPlotModal = true
    },
    closeModalPlot() {
      this.dialogPlotModal = false
    },
    async reloadFarmsList() {
      this.dialogModalFarm = false
      await this.fetchFarms(this.$route?.query?.fazenda_id)
    },
    setFarm(farm) {
      this.farm = farm
    },
  },
}
</script>
